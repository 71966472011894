// Main sass file
import {
    inArray
} from "jquery";
import "../scss/public.scss"
let passd = true;

class validatorClass {

    // Validate Email Address
    isValidEmailAddress(emailAddress) {
        'use strict';
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    }

    // Validate Phone number
    telephoneCheck(str) {
        var patt = new RegExp(/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm);
        return patt.test(str);
    }

    // Validate inputs
    validateInput(elem, type) {
        passd = true;
        switch (type) {
            case 'text':
                if (elem.value == '') {
                    elem.nextElementSibling.classList.remove('d-none');
                    passd = false;
                } else {
                    if (elem.value.length <= 2) {
                        elem.nextElementSibling.classList.remove('d-none');
                        passd = false;
                    } else {
                        elem.nextElementSibling.classList.add('d-none');
                    }
                }
                break;

            case 'file':
                if (elem.value.length <= 4) {
                    elem.parentElement.nextElementSibling.classList.remove('d-none');
                    passd = false;
                } else {
                    elem.parentElement.nextElementSibling.classList.add('d-none');
                }
                break;

            case 'email':
                if (elem.value == '') {
                    elem.nextElementSibling.classList.remove('d-none');
                    passd = false;
                } else {
                    if (this.isValidEmailAddress(elem.value) == false) {
                        elem.nextElementSibling.classList.remove('d-none');
                        passd = false;
                    } else {
                        elem.nextElementSibling.classList.add('d-none');
                    }
                }
                break;

            case 'phone':
                console.log('aqui');
                if (elem.value == '') {
                    elem.nextElementSibling.classList.remove('d-none');
                    passd = false;
                } else {
                    if (this.telephoneCheck(elem.value) == false) {
                        elem.nextElementSibling.classList.remove('d-none');
                        passd = false;
                    } else {
                        elem.nextElementSibling.classList.add('d-none');
                    }
                }
                break;

            case 'select':
                if (elem.value == '') {
                    elem.nextElementSibling.classList.remove('d-none');
                    passd = false;
                } else {
                    elem.nextElementSibling.classList.add('d-none');
                }
                break;

            case 'radio':
                var formValid = false;
                for (let index3 = 0; index3 < elem.length; index3++) {
                    console.log(elem[index3]);
                    if (elem[index3].checked) {
                        formValid = true;
                        break;
                    }
                }

                if (formValid == false) {
                    document.getElementById('radioValidator').classList.remove('d-none');
                    passd = false;
                } else {
                    document.getElementById('radioValidator').classList.add('d-none');
                }
                break;

            case 'checkbox':
                var formValid = false;
                if (elem.checked) {
                    formValid = true;
                    elem.parentElement.parentElement.nextElementSibling.classList.add('d-none');
                } else {
                    elem.parentElement.parentElement.nextElementSibling.classList.remove('d-none');
                    passd = false;
                }
                break;


            default:
                if (elem.value == '') {
                    elem.nextElementSibling.classList.remove('d-none');
                    passd = false;
                } else {
                    if (elem.value.length <= 2) {
                        elem.nextElementSibling.classList.remove('d-none');
                        passd = false;
                    } else {
                        elem.nextElementSibling.classList.add('d-none');
                    }
                }
                break;
        }

        return passd;
    }
}

let validation = new validatorClass();

var slider = tns({
    container: '.dealers-carousel--slider',
    slideBy: 'page',
    autoplay: true,
    mouseDrag: true,
    lazyload: true,
    gutter: 15,
    controls: false,
    nav: false,
    slideBy: 1,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    responsive: {
        576: {
            items: 2
        },
        768: {
            items: 4
        },
        992: {
            items: 6
        },
        1200: {
            items: 7,
        },
        1400: {
            items: 9,
        }
    }
    // autoWidth: true,
})

function validateInputs() {
    var passdArray = []
    var containerInputs = document.querySelectorAll(".active input");
    for (let index = 0; index < containerInputs.length; index++) {
        var type = containerInputs[index].getAttribute('type');
        if (type == 'radio') {
            passdArray[index] = validation.validateInput(containerInputs, type);
        } else {
            passdArray[index] = validation.validateInput(containerInputs[index], type);
        }
    }
    let checker = arr => arr.every(Boolean);
    return checker(passdArray);
}

// Init variables
var currentFormLayer = 1
var lastFormLayer = document.querySelectorAll(".form-layer-step").length

// Set step count
document.getElementById("steps-count").innerHTML = (lastFormLayer - 1)
document.getElementById("current-step").innerHTML = currentFormLayer

// Remove active class from all steps
function clearActive() {
    let steps = document.querySelectorAll(".form-layer-step")
    Array.from(steps).forEach((el) => el.classList.remove("active"));

    document.querySelector(".modal-header__logo").classList.remove("last-step", "first-step")
    document.querySelector(".modal-footer__steps").classList.remove("last-step", "first-step")

    if (currentFormLayer == lastFormLayer) {
        document.querySelector(".modal-header__logo").classList.add("last-step")
        document.querySelector(".modal-footer__steps").classList.add("last-step")
    }

    if (currentFormLayer == 1) {
        document.querySelector(".modal-header__logo").classList.add("first-step")
        document.querySelector(".modal-footer__steps").classList.add("first-step")
    }

    if (currentFormLayer >= (lastFormLayer - 1)) {
        document.querySelector(".modal-footer__steps").classList.add("last-step")
    }
}

// Next step event handler
function formNextLayer(force = false) {
    if (currentFormLayer < lastFormLayer || force) {
        passd = validateInputs()
        if (passd == true) {
            currentFormLayer++
            clearActive()
            document.querySelector(".form-layer-step__" + currentFormLayer).classList.add("active")
            document.getElementById("current-step").innerHTML = currentFormLayer
        }
    }
}

// Prev step event handler
function formPrevLayer() {
    if (currentFormLayer > 1) {
        currentFormLayer--
        clearActive()
        document.querySelector(".form-layer-step__" + currentFormLayer).classList.add("active")
        document.getElementById("current-step").innerHTML = currentFormLayer
    }
}

document.querySelectorAll(".form-next-step").forEach((el) => el.addEventListener("click", function(e) {
    e.preventDefault()
    formNextLayer()
}))

document.querySelectorAll(".form-prev-step").forEach((el) => el.addEventListener("click", function(e) {
    e.preventDefault()
    formPrevLayer()
}))

jQuery('#form-register').ajaxForm({
    "clearForm": true,
    "resetForm": true,
    "beforeSubmit": (arr, $form, options) => {
        jQuery(".form-submit-layer").addClass("loading")
    },
    "success": (responseText, statusText, xhr, $form) => {
        jQuery(".form-submit-layer").removeClass("loading")
        formNextLayer(true)
        document.querySelector(".modal-header").classList.add("modal-header--success")
        document.querySelector(".modal-footer").classList.add("d-none")
    },
    "error": () => {
        jQuery(".form-submit-layer").removeClass("loading")
        formNextLayer(true)
        document.querySelector(".modal-header").classList.add("modal-header--success")
        document.querySelector(".modal-footer").classList.add("d-none")
    }
})

// jQuery(".form-submit").on("click", function(e) {
//     jQuery('#form-register').trigger('submit')
// })

document.getElementById('register-modal')
    .addEventListener('d-none.bs.modal', () => {
        document.querySelector(".modal-header").classList.remove("modal-header--success")
        document.querySelector(".modal-footer").classList.remove("d-none")
        currentFormLayer = 2
        formPrevLayer()
    })
